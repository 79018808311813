import { downloadFromResponse } from '@/core/helpers/files-helper';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { format } from 'date-fns';
import { map } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class FinanceService {
  #http = inject(HttpClient);

  settlement(accommodationId: string | number, range: [Date, Date], type: 'json' | 'pdf') {
    const url = `//api/finance/settlement/${accommodationId}`;
    const [from, to] = range.map((date) => format(date, 'yyyy-MM-dd'));
    const params = { from, to };
    const headers = { accept: `application/${type}` };
    const responseType: any = type === 'json' ? 'json' : 'blob';
    const options = { responseType, params, headers, observe: 'response' };
    const response = this.#http.get(url, options as any);

    if (type === 'pdf') {
      return response.pipe(
        map((response: any) => {
          const timestamp = format(new Date(), 'yyyyMMdd');
          const defaults = {
            name: `settlement-${accommodationId}-${timestamp}.pdf`,
            type: 'application/pdf'
          };

          return downloadFromResponse(response, { defaults, forceDefaults: true });
        })
      );
    } else {
      return response.pipe(map((response: any) => response.body));
    }
  }

  invoice(accommodationId: string | number, range: [Date, Date], type: 'json' | 'pdf') {
    const url = `//api/finance/invoice/${accommodationId}`;
    const [from, to] = range.map((date) => format(date, 'yyyy-MM-dd'));
    const params = { from, to };
    const headers = { accept: `application/${type}` };
    const responseType: any = type === 'json' ? 'json' : 'blob';
    const options = { responseType, params, headers, observe: 'response' };
    const response = this.#http.get(url, options as any);

    if (type === 'pdf') {
      return response.pipe(
        map((response: any) => {
          const timestamp = format(new Date(), 'yyyyMMdd');
          const defaults = {
            name: `invoice-${accommodationId}-${timestamp}.pdf`,
            type: 'application/pdf'
          };

          return downloadFromResponse(response, { defaults, forceDefaults: true });
        })
      );
    } else {
      return response.pipe(map((response: any) => response.body));
    }
  }
}
