<h2>{{ ticket.automatic ? '🤖' : '' }}{{ ticket.title }}</h2>
<p *ngIf="ticket.description?.trim()">{{ ticket.description }}</p>
<div class="ticket-tags">
  <div
    *ngFor="let tag of ticket.tags || []; trackBy: trackById"
    class="badge"
    [style.color]="tag?.color | contrast"
    [style.background-color]="tag?.color || '#AAAAAA'"
  >
    <i class="ri-coupon-3-line"></i>
    <span> {{ tag?.name }} </span>
    <i class="ri-close-line text-sm" *can="'control+4'" (click)="removeTag(tag)"></i>
  </div>
</div>
<div class="flex flex-col w-full mb-2 flex-1 overflow-hidden">
  <div role="tablist" class="tabs tabs-bordered mr-auto items-center">
    <a role="tab" class="tab" [class.tab-active]="tab === 'info'" (click)="tab = 'info'">
      Información
    </a>
    <a
      role="tab"
      class="tab gap-2"
      [class.tab-active]="tab === 'checklist'"
      (click)="tab = 'checklist'"
    >
      <span> Tareas ({{ checklist?.completed || 0 }} / {{ ticket.tasks?.length || 0 }}) </span>
    </a>
    @if (tab === 'checklist') {
      <button
        *can="'vision+4,control+4,operation+3'"
        class="btn bg-base-200 p-0 h-6 w-6 min-h-0 min-w-0 rounded-md mx-2 row-start-1"
        [ngClass]="{
          '!bg-primary': editable,
          'text-white': editable
        }"
        (click)="editable = !editable"
      >
        <i class="ri-edit-2-line"></i>
      </button>
    }
  </div>
  <div class="flex-1 flex flex-col overflow-y-auto p-2">
    @switch (tab) {
      @case ('info') {
        <div class="flex flex-col p-2">
          <div class="ticket-field" *ngIf="formated?.pack !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Pack: </span>
            <span> {{ formated.pack }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.creator_id !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Creado por: </span>
            <span> {{ formated.creator_id }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.localizator !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Localizador: </span>
            <span> {{ formated.localizator }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.accommodation !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Piso: </span>
            <span>{{ formated.accommodation }}</span>
          </div>
          <div class="ticket-field" *ngIf="formated?.client !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Cliente: </span>
            <span [innerHTML]="formated.client"> </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.address !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Dirección: </span>
            <span> {{ formated.address }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.origin !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Origen: </span>
            <span> {{ formated.origin }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.adults !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Adultos: </span>
            <span> {{ formated.adults }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.childs !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Niños: </span>
            <span> {{ formated.childs }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.web !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Canal de venta: </span>
            <span [innerHTML]="formated.web"> </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.phone !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Teléfono: </span>
            <span> {{ formated.phone }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.arrival_date !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Llegada: </span>
            <span> {{ formated.arrival_date }} </span>
          </div>
          <div class="ticket-field" *ngIf="formated?.departure_date !== 'n/a'">
            <span class="font-semibold text-opacity-80"> Salida: </span>
            <span> {{ formated.departure_date }} </span>
          </div>
        </div>
      }
      @default {
        <app-checklist
          #checklist
          [ticketId]="ticket.id"
          [editable]="editable"
          [disabled]="ticket.status === 'resolved' || ticket?.status === 'canceled'"
          [(ngModel)]="ticket.tasks"
          (check)="tasks.mark($event).subscribe(patchTask.bind(this))"
          (created)="tasks.create($event).subscribe(patchTask.bind(this))"
          (updated)="tasks.update($event).subscribe(patchTask.bind(this))"
          (deleted)="deleteTask($event)"
        >
        </app-checklist>
      }
    }
  </div>
</div>
<div class="flex flex-col w-full p-2 flex-initial">
  <div class="attachments">
    <span class="mr-auto"> Adjuntos </span>
    @if(!readonly){
    <i class="ri-download-line cursor-pointer text-info" (click)="download()"></i>
    <label class="cursor-pointer" for="file-input">
      <i class="ri-file-add-line text-success"></i>
      <input hidden multiple id="file-input" type="file" (change)="filesChange($event)" />
    </label>
    }
  </div>
  <app-attachments
    [readonly]="readonly"
    [attachments]="ticket.attachments"
    (remove)="removeAttachment($event)"
  ></app-attachments>
  <div class="file-backdrop" [style.--top]="ref.nativeElement.scrollTop">
    <span> Soltar aqui para añadir </span>
  </div>
</div>
