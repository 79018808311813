@if (value) {
  @if (range) {
    <span class="flex-1">
      {{ value[0] | date: 'dd-MM-YYYY' }}
      <pre class="inline-flex text-primary">,</pre>
      {{ value[1] | date: 'dd-MM-YYYY' }}
    </span>
  } @else {
    <span> {{ value | date: 'dd-MM-YYYY' }} </span>
  }
} @else {
  <span class="text-neutral">{{ placeholder }}</span>
}
@if (range) {
  <mat-date-range-picker #picker (closed)="select(input.value)"></mat-date-range-picker>
  <mat-date-range-input [rangePicker]="picker" #input>
    <input matStartDate [disabled]="disable" [min]="min" placeholder="Start date" />
    <input matEndDate [disabled]="disable" [max]="max" placeholder="End date" />
  </mat-date-range-input>
} @else {
  <mat-datepicker #picker (closed)="select(input.value)"></mat-datepicker>
  <input class="input" matInput [matDatepicker]="picker" #input />
}
<button class="icon p-0 ml-auto" type="button">
  <i class="ri-calendar-event-line text-sm text-soot"></i>
</button>
