import { environment } from 'src/environments/environment';
import { Entity } from './base';
import { Attachment } from './attachment';

export class Comment extends Entity<Comment> {
  user?: string;
  userId?: number | string;
  userImage?: string;
  reason?: string;
  message: string;
  content?: string;
  ticketId?: string;
  data?: any;
  metadata?: any;
  extras?: Record<string, any>;
  attachments?: Attachment[]; 

  get picture() {
    if (this.userImage) return `${environment.apiUrl}${this.userImage}`;
    return 'assets/images/default-profile.jpg';
  }

  get isAutomatic() {
    return this.extras?.automatic;
  }

  constructor(obj?: Partial<Comment>) {
    super(obj);
    Object.assign(this, obj);
    try {
      if (typeof this.data === 'string') {
        this.data = JSON.parse(this.data);
      }
      if (typeof this.extras === 'string') {
        this.extras = JSON.parse(this.extras);
      }
    } catch (e) {
      this.data = this.data ?? null;
      this.extras = this.extras ?? null;
    }

    this.extras ||= {};

    this.attachments ||= [];
  }

  toRequest() {
    const { data, extras, message, content, reason, attachments, ticketId } = this;
    return {
      reason,
      message,
      content,
      data: JSON.stringify(data),
      extras: JSON.stringify(extras),
      ticket_id: this.ticketId,
      attachments,
      ticketId
    };
  }
}
