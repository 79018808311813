import { Component } from '@angular/core';
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../../core/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: { class: 'page' }
})
export class LoginComponent {
  form: UntypedFormGroup;

  constructor(private auth: AuthService, private router: Router) {
    if (this.auth.authenticated) this.router.navigateByUrl('');
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.email
      ]),
      password: new UntypedFormControl('', [Validators.required])
    });
  }

  login(event: SubmitEvent) {
    event?.preventDefault();
    this.auth.login(this.form.value).subscribe(() => {
      this.router.navigateByUrl('/operations/mytickets');
    });
  }
}
