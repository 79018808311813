import { NgIf } from '@angular/common';
import { Component, ElementRef, HostBinding, inject, input, output, signal } from '@angular/core';

export type ButtonClickEvent = MouseEvent & { complete: () => void };

@Component({
  standalone: true,
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  host: { class: 'btn' },
  imports: [NgIf]
})
export class ButtonComponent {
  ref = inject(ElementRef);
  disabled = input<boolean>(false);
  block = input<boolean>(false);
  click = output<ButtonClickEvent>();
  loading = signal(false);

  @HostBinding('attr.inert')
  @HostBinding('attr.disabled')
  get inert() {
    return this.disabled() ? '' : null;
  }

  constructor() {
    this.ref.nativeElement.addEventListener('click', (e: MouseEvent) => this.onClick(e));
  }

  onClick(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();
    event.stopImmediatePropagation();

    if (this.loading() && this.block()) {
      return;
    }

    this.loading.set(true);
    this.click.emit({
      ...event,
      complete: () => this.loading.set(false)
    });
  }
}
