<section class="nav" (dblclick)="more = !more">
  <a
    class="avatar cursor-pointer mb-2 hidden md:inline-flex"
    routerLink="/auth/profile"
    routerLinkActive="active"
  >
    <div class="w-12 rounded-full">
      <img
        [src]="auth.user?.image"
        onError="this.onerror=null;this.src='/assets/images/noimage.jpg';"
      />
    </div>
  </a>
  <a class="nav-item flex md:hidden" routerLink="/auth/profile" routerLinkActive="active">
    <i class="ri-user-line"></i>
  </a>
  <a *can="'operation+1'" class="nav-item flex" routerLink="/operations" routerLinkActive="active">
    <i class="ri-coupon-3-line"></i>
  </a>
  <a *can="'management+1'" class="nav-item flex" routerLink="/pms" routerLinkActive="active">
    <i class="ri-community-line"></i>
  </a>
  <a
    *can="'!host|vision+3,control+2'"
    class="nav-item flex"
    routerLink="/reports"
    routerLinkActive="active"
  >
    <i class="ri-folder-chart-line"></i>
  </a>
  <a
    *can="'!host|vision+3,management+3,control+3'"
    class="nav-item hidden md:flex"
    routerLink="/legacy/bookings"
    routerLinkActive="active"
  >
    <i class="ri-database-2-line"></i>
  </a>
  <a *ngIf="dev" class="nav-item flex" routerLink="/lab" routerLinkActive="active">
    <i class="ri-microscope-line"></i>
  </a>
</section>
<section class="nav nav-more" [style.--qty]="5">
  <a class="nav-item flex sm:indicator" (click)="notification()">
    <i class="ri-notification-line"></i>
    <span> Notificaciones </span>
    <div
      class="indicator-item badge badge-primary badge-notification"
      *ngIf="notifications.unread$ | async as unread"
    >
      {{ unread }}
    </div>
  </a>
  <a
    class="nav-item flex"
    *can="'!host|control+4,vision+4'"
    routerLink="/admin"
    routerLinkActive="active"
    [style.--index]="1"
  >
    <i class="ri-shield-line"></i>
    <span> Administracion </span>
  </a>
  <a
    class="nav-item flex"
    *can="'!host|operation+2'"
    routerLinkActive="active"
    [style.--index]="2"
    (click)="app.emit('app:search', true)"
  >
    <i class="ri-search-line"></i>
    <span> Buscar </span>
  </a>
  <a
    class="nav-item flex"
    *can="'!host'"
    routerLink="/system/helps"
    routerLinkActive="active"
    [style.--index]="3"
  >
    <i class="ri-question-line"></i>
    <span> Ayuda </span>
  </a>
  <a class="nav-item flex" [style.--index]="4" (click)="auth.logout()">
    <i class="ri-logout-circle-line"></i>
    <span> Cerrar sesion </span>
  </a>
</section>
<a class="more-arrow" (click)="more = !more">
  <i class="{{ more ? 'ri-arrow-down-s-line' : 'ri-arrow-up-s-line' }}"></i>
</a>
<button *tenancy="'central'" class="tenancy-box" (click)="tenancy.select().subscribe()">
  <span> {{ tenant }} </span>
</button>
