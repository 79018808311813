<h3 class="text-base font-semibold text-soot p-1 flex items-center gap-2">
  <span class="mr-auto">
    {{ data.title || 'Formulario sin titulo' }}
  </span>
  <i
    *ngIf="editable"
    class="text-sm ri-edit-line cursor-pointer text-info"
    (click)="edit.emit(data)"
  ></i>
  <i
    *ngIf="removable"
    class="text-sm ri-delete-bin-6-line cursor-pointer text-error"
    (click)="remove.emit(data)"
  ></i>
</h3>
<div class="mt-2 flex flex-col gap-2 max-h-48 overflow-y-auto">
  <span
    *ngFor="let control of data.controls || []"
    class="flex flex-row items-center border border-smoke rounded p-2 gap-2"
  >
    <div
      [ngSwitch]="control.type ?? 'text'"
      class="flex items-center justify-center bg-smoke rounded w-8 h-8"
    >
      <i *ngSwitchCase="'text'" class="ri-text"></i>
      <i *ngSwitchCase="'number'" class="ri-hashtag"></i>
      <i *ngSwitchCase="'select'" class="ri-list-check"></i>
      <i *ngSwitchCase="'file'" class="ri-article-line"></i>
    </div>
    <div class="flex flex-col flex-1">
      <label class="text-xs font-semibold"> {{ control.title }} </label>
      <ng-container *ngIf="data.content; else ph">
        <ng-container [ngSwitch]="control.type ?? 'text'">
          <span *ngSwitchCase="'file'" class="text-sm flex items-center w-full justify-between">
            {{ data.content[control.title] ? 'Cargado' : 'Vacio' }}
            <i
              *ngIf="data.content[control.title]"
              class="ri-external-link-line text-info cursor-pointer"
              (click)="toFile(data.content[control.title])"
            >
            </i>
          </span>
          <span *ngSwitchDefault class="text-sm">
            {{ data.content[control.title] }}
          </span>
        </ng-container>
      </ng-container>
      <ng-template #ph>
        {{ control.placeholder }}
      </ng-template>
    </div>
  </span>
</div>
