<section class="flex flex-row flex-nowrap items-center justify-start gap-1 px-2 py-1">
  <button class="btn btn-icon shadow-none bg-transparent md:hidden" (click)="ref?.close()">
    <i class="ri-arrow-left-s-line text-primary !text-3xl"></i>
  </button>
  <h2 class="hidden text-xl sm:inline-flex md:text-2xl text-primary">Notificaciones</h2>
  <span class="mx-auto"></span>
  <label class="btn btn-switch" [class.btn-primary]="readed" [class.text-white]="readed">
    <i class="ri-list-check-2" *ngIf="!readed"></i>
    <i class="ri-list-check-3" *ngIf="readed"></i>
    <input
      type="checkbox"
      checked="checked"
      class="toggle toggle-primary border-none"
      [(ngModel)]="readed"
    />
  </label>
  <button class="btn btn-primary text-white w-10 h-10 min-h-0" (click)="readAll()">
    <i class="ri-check-double-line"></i>
  </button>
</section>
<div #container class="flex flex-col gap-2 p-2 overflow-auto">
  <app-notification-card
    #item
    *ngFor="let notification of datasource.items$ | async; trackBy: tracker"
    [notification]="notification"
  ></app-notification-card>
  <span class="no-items" *ngIf="datasource.loading$ | async">
    <i class="ri-loader-2-line text-2xl text-primary font-extrabold spin"></i>
  </span>
  <span class="no-items" *ngIf="!datasource.items?.length && !datasource.loading">
    <i class="ri-notification-3-line text-4xl text-primary text-opacity-30 font-extrabold"></i>
    <p class="text-center text-primary text-opacity-30">No hay notificaciones</p>
  </span>
</div>
