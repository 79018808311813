import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { CommentTrigger } from './comments.types';

export function buildModules(triggers: CommentTrigger[]): QuillModules {
  const chars = triggers.map((t) => t.char);

  return {
    toolbar: null,
    magicUrl: true,
    mention: {
      positioningStrategy: 'fixed',
      defaultMenuOrientation: 'top',
      mentionDenotationChars: chars,
      allowedChars: /^[A-Za-z\sáéíóúñ]*$/,
      dataAttributes: ['id', 'denotationChar', 'key'],
      source: (term: string, render: Function, char: string) => {
        const search = term.toLowerCase() || '';
        const trigger = triggers.find((t) => t.char === char);
        const items = trigger?.items || [];
        const result = items
          .filter(({ value }) => {
            return value.toLowerCase().search(search) != -1;
          })
          .slice(0, 20)
          .map(({ id, value }) => ({ id, value, key: trigger.key }));
        render(result, term);
      }
    }
  };
}

export function getMentions(editor: QuillEditorComponent) {
  const elements = editor.editorElem.querySelectorAll('.mention');
  const mentions = {};

  elements.forEach((mention: HTMLElement) => {
    const { id, key } = mention.dataset;

    mentions[key] ||= [];
    if (!mentions[key].includes(id)) {
      mentions[key].push(id);
    }
  });

  return mentions;
}
