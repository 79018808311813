import { NgClass } from '@angular/common';
import {
  Component,
  HostBinding,
  HostListener,
  Input,
  OnInit
} from '@angular/core';
import { DialogService } from '@mukhuve/ngx/dialog';
import { TicketViewComponent } from 'src/app/shared/components/ticket-view/ticket-view.component';

@Component({
  standalone: true,
  selector: 'app-ticket-icon',
  templateUrl: './ticket-icon.component.html',
  styleUrls: ['./ticket-icon.component.scss'],
  imports: [NgClass]
})
export class TicketIconComponent implements OnInit {
  @Input() icon: string;
  @Input() ticket: {
    id: number;
    icon: string;
    status: string;
    action?: string;
    assigned?: string;
    priority?: number;
    warning?: boolean;
  };

  constructor(public dialog: DialogService) {}

  ngOnInit() {}

  @HostBinding('class')
  get color() {
    const { status } = this.ticket || ({} as any);
    switch (status || '') {
      case 'running':
        return 'bg-info';
      case 'resolved':
        return 'bg-success';
      case 'canceled':
      case 'unresolved':
        return 'bg-error';
      default:
        return 'bg-smoke';
    }
  }

  @HostListener('click')
  click() {
    const inputs = { id: this.ticket?.id };
    this.dialog.open(TicketViewComponent, { inputs });
  }
}
