import { PreviewDirective } from '@/shared/directives/preview.directive';
import { UXModule } from '@/ux/ux.module';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, inject, input, Input, output, Output } from '@angular/core';
import { DialogService } from '@mukhuve/ngx/dialog';
import { UXService } from 'src/app/ux/services/ux.service';
import { FilesDialogComponent } from '../files-dialog/files-dialog.component';
import { Attachment } from '@/core/models/attachment';

@Component({
  standalone: true,
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss'],
  imports: [CommonModule, UXModule, PreviewDirective]
})
export class AttachmentsComponent {
  dialog = inject(DialogService);
  http = inject(HttpClient);
  ux = inject(UXService);

  attachments = input<Attachment[]>([]);
  readonly = input<boolean>(false);
  size = input<'sm' | 'md'>('md');

  removeEmitter = output<Attachment>({ alias: 'remove' });

  remove(attachment: Attachment, event: Event) {
    event.stopImmediatePropagation();
    event.stopPropagation();

    this.removeEmitter.emit(attachment);
  }

  preview(index: any) {
    const files = this.attachments();
    this.dialog.open(FilesDialogComponent, {
      inputs: { files, index },
      classes: 'bg-transparent',
      disableAnimation: true,
      type: 'push'
    });
  }
}
