<section
  class="flex items-center justify-between gap-2 order-3 md:order-1 md:justify-start w-full md:w-auto md:mr-auto ticket-info"
>
  <button
    class="ticket-status ticket-status--{{ ticket?.status || 'pending' }}"
    [disabled]="['canceled', 'resolved', 'unassigned'].includes(ticket?.status) || readonly"
    (click)="openStatusMenu()"
  >
    <span> {{ ticket?.status | translate }} </span>
    <app-dropdown #statusMenu>
      @if (ticket?.assigned_id !== auth.user?.id && ticket.status === 'approval') {
        <li (click)="approve(false)">
          <a class="hover:bg-error hover:text-base-100">
            <i class="ri-thumb-down-line"></i>
            No Aprobar
          </a>
        </li>
        <li *can="'operation+3'" (click)="approve()">
          <a class="hover:bg-success hover:text-base-100">
            <i class="ri-thumb-up-line"></i>
            Aprobar
          </a>
        </li>
      }
      @if (ticket?.assigned_id === auth.user?.id) {
        <li (click)="cancel()">
          <a class="hover:bg-error hover:text-base-100">
            <i class="ri-close-line"></i>
            Cancelar
          </a>
        </li>
        @switch (ticket?.status) {
          @case ('pending') {
            <li (click)="start()">
              <a class="hover:bg-info hover:text-base-100">
                <i class="ri-play-line"></i>
                Iniciar
              </a>
            </li>
          }
          @case ('running') {
            <li (click)="finish()">
              <a class="hover:bg-success hover:text-base-100">
                <i class="ri-check-line"></i>
                Finalizar
              </a>
            </li>
          }
        }
      }
    </app-dropdown>
  </button>
  <button
    class="btn btn-primary order-2 w-10 !h-10 hidden sm:flex"
    *can="'vision+4,control+4,operation+3'"
  >
    <i class="ri-instance-line text-xl" (click)="$event.stopPropagation(); finishDropdown.open()">
    </i>
    <app-dropdown #finishDropdown>
      <li (click)="service.spreadStatus(ticket?.id).subscribe()">
        <a class="hover:bg-primary hover:text-base-100 items-center">
          <i class="ri-arrow-right-circle-line"></i>
          <span class="flex items-center"> Propagar estado </span>
        </a>
      </li>
    </app-dropdown>
  </button>
  <div
    class="ticket-assigned order-2"
    [matTooltip]="ticket?.assigned?.name ?? 'Asignar responsable de la tarea'"
    [quickedit]="'select'"
    [quickeditTitle]="'Asignar a'"
    [quickeditValue]="ticket?.assigned_id"
    [quickeditOptions]="users$ | async"
    (edited)="assign($event)"
    [class.cursor-not-allowed]="readonly"
    [class.pointer-events-none]="readonly"
  >
    @if (ticket?.assigned) {
      <img
        class="w-full h-full object-cover"
        [src]="ticket?.assigned?.image"
        onError="this.onerror=null;this.src='/assets/images/default-profile.jpg';"
      />
      <i class="icon-suffix ri-arrow-left-right-line"></i>
    } @else {
      <i class="ri-user-3-line"></i>
      <i class="icon-suffix ri-add-line"></i>
    }
  </div>
  <div
    class="flex flex-col justify-center mr-auto order-3 !leading-5 text-xs md:text-sm lg:text-base"
  >
    <span class="flex items-center gap-1" [style.color]="ticket?.action?.color">
      <i [class]="ticket?.action?.icon"></i>
      <span>
        {{ ticket?.action?.name }}
        <span class="hidden lg:inline-block"> #{{ ticket?.id }} </span>
      </span>
    </span>
    <span class="flex items-center gap-1">
      <i class="ri-calendar-line"></i>
      <span> {{ ticket?.date | date: 'MMM d, y, h:mm a' }} </span>
    </span>
  </div>
</section>
<section
  class="flex flex-row items-center justify-between gap-2 md:justify-start order-1 md:order-3 w-full md:w-auto"
>
  <ng-content></ng-content>

  @if (!readonly) {
    <div class="join join-horizontal hidden md:flex">
      @if (ticket?.booking_id) {
        <button class="btn btn-ghost" [matTooltip]="'Tickets de la misma reserva'">
          <i class="ri-file-list-3-line text-info" (click)="related()"></i>
        </button>
      }
      <button class="btn btn-ghost" [matTooltip]="'Nuevo ticket relacionado'">
        <i class="ri-add-line text-info" (click)="relatedTicket()"></i>
      </button>
      <button
        class="btn btn-ghost text-bloom"
        [matTooltip]="'Copiar enlace a este ticket'"
        (click)="ticketLink()"
      >
        <i class="ri-link"></i>
      </button>
      <button
        class="btn btn-ghost text-accent"
        [matTooltip]="ticket.subscribed ? 'No notificarme' : 'Notificarme'"
        (click)="toggleSubscription()"
      >
        <i class="ri-notification-{{ ticket?.subscribed ? 'off' : '4' }}-line"></i>
      </button>
      <button
        class="btn btn-ghost text-success"
        [matTooltip]="'Refrescar'"
        (click)="updated.emit()"
      >
        <i class="ri-refresh-line" [class.spin]="isLoading"></i>
      </button>
    </div>
    @if (ticket?.accommodation_channels?.length) {
      <button class="btn btn-ghost" tabindex="-1" (click)="channels.open()">
        <i class="ri-external-link-line"></i>
        <app-dropdown #channels>
          @for (channel of ticket?.accommodation_channels; track channel?.name) {
            <li (click)="goToChannel(channel.url)">
              <span>{{ channel.name }}</span>
              <span> <i class="ri-external-link-line"></i></span>
            </li>
          }
        </app-dropdown>
      </button>
    }
    <div class="join join-horizontal ml-auto md:mr-0">
      <button class="btn btn-ghost join-item" [disabled]="!around?.prev" (click)="move('prev')">
        <i class="ri-arrow-left-s-fill"></i>
      </button>
      <button class="btn btn-ghost join-item" [disabled]="!around?.next" (click)="move('next')">
        <i class="ri-arrow-right-s-fill"></i>
      </button>
    </div>
    <button class="btn btn-ghost" (click)="menu.open()">
      <i class="ri-more-2-line"></i>
      <app-dropdown #menu>
        <li (click)="edit()">
          <span> Editar Ticket </span>
        </li>
        @if (ticket.assigned_id && !ticket.finished_at && !ticket.canceled_at) {
          <li (click)="unassign()">
            <span> Desasignar </span>
          </li>
        }
        <!-- <li (click)="expenses()">
          <span> Costes </span>
        </li> -->
      </app-dropdown>
    </button>
    @if (ref) {
      <button tabindex="-1" class="btn btn-ghost btn-close" (click)="ref?.close()">
        <i class="ri-close-line"></i>
      </button>
    }
  }
</section>
<ng-template #ticketTemplate let-item="item" let-i="index">
  <app-ticket-card [ticketId]="item?.id"></app-ticket-card>
</ng-template>
