import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { format } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { switchMap } from 'rxjs/operators';
import { Booking } from 'src/app/pms/models/booking';
import { UXService } from 'src/app/ux/services/ux.service';

@Injectable({ providedIn: 'root' })
export class BookingService {
  refreshData$ = new BehaviorSubject<boolean>(true);
  constructor(private http: HttpClient, private ux: UXService) {}

  one(id: number | string) {
    return this.refreshData$.pipe(
      switchMap((_) => this.http.get<any>(`//api/bookings/${id}`))
    );
  }

  update(id: number | string, body: any) {
    return this.http.put(`//api/pms/bookings/${id}`, body).pipe(
      tap({
        next: () => this.ux.notifier.success('¡Reserva actualizada!'),
        error: () => this.ux.notifier.error('¡Error al actualizar reserva!')
      })
    );
  }

  management(params?: any) {
    return this.http.get<any>(`//api/pms/management`, { params });
  }

  planing(params?: any) {
    return this.http.get<any>(`//api/pms/planing`, { params });
  }

  syncFromProvider(booking: any) {
    return this.http.post(`//api/integration/avantio/sync/${booking.id}`, {});
  }

  search(localizator: string, sync: boolean = false) {
    const url = '//api/integration/avantio/search';

    return this.http.post<any>(url, { localizator, sync });
  }
}
