<ng-container *ngIf="!empty; else holder">
  <div class="option no-hover" [innerHTML]="label | sanitize : 'html'"></div>
</ng-container>
<ng-template #holder>
  <div class="option no-hover" #ref>
    <ng-content select="[placeholder]"></ng-content>
    <ng-container *ngIf="!ref.childNodes?.length">
      <span class="option-default"> ---- </span>
    </ng-container>
  </div>
</ng-template>

<ng-container *ngIf="arrow">
  <i class="select-arrow ri-arrow-down-s-line" *ngIf="options?.length; else loading"></i>
  <ng-template #loading>
    <i class="select-arrow ri-loader-2-line spin"></i>
  </ng-template>
</ng-container>
<app-dropdown>
  <input *ngIf="filter" type="text" class="input min-h-[3rem]" [(ngModel)]="search" />
  <div
    *ngFor="let option of options | filter : search : 'label'"
    class="option"
    [innerHTML]="option.label | sanitize : 'html'"
    (click)="writeValue(option.value); close()"
  ></div>
</app-dropdown>
