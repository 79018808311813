<section class="comments-topbar">
  <div class="join w-full gap-2 px-4 py-2">
    <i class="ri-search-line join-item"></i>
    <input
      class="flex-1 join-item bg-transparent outline-none"
      placeholder="Filtrar..."
      [(ngModel)]="search"
      (keyup)="load()"
    />
    <i
      class="ri-delete-back-2-line join-item cursor-pointer text-error"
      [class.hidden]="!search.length"
      (click)="search = ''; load()"
    ></i>
  </div>
</section>
@if (triggers$ | async; as triggers) {
  <section class="comments-box" #box>
    <ng-container>
      @for (comment of comments$ | async; track comment.id) {
        <app-comment-message
          [readonly]="readonly"
          [comment]="comment"
          [triggers]="triggers"
          (edited)="edit($event)"
          (removed)="remove($event)"
        >
        </app-comment-message>
      } @empty {
        @if (!loading()) {
          <span class="no-items"> No hay comentarios para mostrar </span>
        }
      }
      @if (loading()) {
        <app-loading></app-loading>
      }
    </ng-container>
  </section>
  <section class="comments-controls">
    <app-comment-input
      [readonly]="readonly"
      [triggers]="triggers"
      (send)="add($event)"
    ></app-comment-input>
  </section>
}
