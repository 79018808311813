import {
  Component,
  ContentChildren,
  HostBinding,
  Input,
  OnInit,
  QueryList,
  TemplateRef
} from '@angular/core';
import { TabComponent } from '../tab/tab.component';

@Component({
  selector: 'tabs',
  templateUrl: './tabs.component.html',
  host: { class: 'flex flex-wrap items-center justify-start' }
})
export class TabsComponent implements OnInit {
  @ContentChildren(TabComponent)
  tabs: QueryList<TabComponent>;

  @Input()
  bar?: TemplateRef<any>;

  @HostBinding('class.vertical')
  @Input()
  vertical = false;

  @HostBinding('class.reverse')
  @Input()
  reverse = false;

  @Input()
  activeClass = 'tab-active';

  constructor() {}

  ngOnInit() {}

  active(tab: TabComponent) {
    this.tabs.forEach((t) => t.active(false));
    tab.active(true, this.activeClass || '');
  }
}
