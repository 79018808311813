<form *ngIf="form" [formGroup]="form" autocomplete="off" (keydown.enter)="$event.preventDefault()">
  <h4 class="form__title">
    <ng-container *ngIf="data?.id; else crate"> Actualizar reserva </ng-container>
    <ng-template #crate>Crear nueva reserva</ng-template>
  </h4>
  <app-stepper #stepper>
    <cdk-step label="Informacion basica">
      <div class="form">
        <div class="form__control">
          <label> Alojamiento* </label>
          <app-select formControlName="accommodation_id">
            <app-option
              *ngFor="let accommodation of accommodations$ | async"
              [value]="accommodation.id"
            >
              {{ accommodation?.name }}
            </app-option>
          </app-select>
        </div>
        <div class="form__control">
          <label> Localizador* </label>
          <input class="input" type="text" formControlName="localizator" />
        </div>
        <div>
          <div class="form__control">
            <label> Entrada* </label>
            <input
              class="input"
              type="date"
              formControlName="arrival_date"
              [min]="now"
              [max]="form.value.departure_date"
            />
          </div>
          <div class="form__control">
            <label> Salida* </label>
            <input
              class="input"
              type="date"
              formControlName="departure_date"
              [min]="form.value.arrival_date"
            />
          </div>
        </div>
        <div>
          <div class="form__control">
            <label> Tipo* </label>
            <app-select formControlName="type" #typeSelect>
              <app-option [value]="'regular'"> Normal </app-option>
              <app-option [value]="'lock'"> Bloqueo </app-option>
            </app-select>
          </div>
          <div class="form__control">
            <label> Status* </label>
            <app-select formControlName="status">
              <ng-container *ngIf="typeSelect.value === 'regular'">
                <app-option [value]="'unpaid'"> Sin Pagar </app-option>
                <app-option [value]="'paid'"> Pagada </app-option>
                <app-option [value]="'confirmed'"> Confirmada </app-option>
              </ng-container>
              <ng-container *ngIf="typeSelect.value === 'lock'">
                <app-option [value]="'owner'"> Propietario </app-option>
                <app-option [value]="'unavailable'"> No disponible </app-option>
              </ng-container>
              <app-option [value]="'canceled'"> Cancelada </app-option>
            </app-select>
          </div>
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Informacion del cliente">
      <div class="form" formGroupName="client">
        <div class="form__control">
          <label> Nombre* </label>
          <input class="input" type="text" formControlName="name" />
        </div>
        <div class="form__control">
          <label> Apellido </label>
          <input class="input" type="text" formControlName="surname" />
        </div>
        <div class="form__control">
          <label> DNI </label>
          <input class="input" type="text" formControlName="dni" />
        </div>
        <div class="form__control">
          <label> Email </label>
          <input class="input" type="email" formControlName="email" />
        </div>
        <div class="form__control">
          <label> Telefono </label>
          <input class="input" type="text" formControlName="phone" />
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Informacion del cliente">
      <div class="form" formGroupName="occupants">
        <div class="form__control">
          <label> Adultos* </label>
          <input class="input" type="number" formControlName="adults" />
        </div>
        <div class="form__control">
          <label> Niños </label>
          <input class="input" type="number" formControlName="children" />
        </div>
      </div>
    </cdk-step>
    <cdk-step label="Informacion del cliente">
      <div class="form" formGroupName="amounts">
        <div class="form__control">
          <label> Precio* </label>
          <input class="input" type="number" formControlName="RentalPrice" />
        </div>
      </div>
    </cdk-step>
  </app-stepper>

  <div class="form__actions">
    <button class="color-smoke" (click)="ref?.close()">Cancelar</button>
    <span class="spacer"></span>
    <button *ngIf="stepper.selectedIndex > 0" class="color-smoke" (click)="stepper.previous()">
      Atrás
    </button>
    <button
      *ngIf="stepper.selectedIndex < stepper.steps?.length - 1"
      class="color-info"
      [disabled]="form.invalid"
      (click)="stepper.next()"
    >
      Siguiente
    </button>
    <button [disabled]="form.invalid" (click)="submit()" matTooltip="Enviar" class="color-success">
      <i class="ri-send-plane-fill"></i>
    </button>
  </div>
</form>
