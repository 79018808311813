<form [formGroup]="form" autocomplete="off">
  <h4 class="text-title">Cambiar contraseña</h4>
  <div class="form__control">
    <label> Nueva contraseña </label>
    <input
      class="input"
      type="password"
      formControlName="password"
      value=""
      autocomplete="new-password"
    />
  </div>
  <div class="form__control">
    <label> Confirme la contraseña </label>
    <input
      class="input"
      type="password"
      formControlName="confirm"
      value=""
      autocomplete="new-password"
    />
  </div>
  <div class="form__actions">
    <button class="color-smoke" (click)="ref?.close()">Cancelar</button>
    <button class="color-primary" [disabled]="form.invalid" (click)="submit()">Guardar</button>
  </div>
</form>
