<div role="tablist" class="tabs tabs-boxed bg-transparent">
  <div
    *ngFor="let tab of tabs ?? []"
    class="tab py-3 px-3 gap-2 tab-lg h-auto sm:h-12 sm:px-4 inline-flex {{ tab.classes }}"
    routerLinkActive="tab-active"
    [routerLink]="tab.link"
    [class.tab-active]="tab.activated"
    (click)="active(tab)"
  >
    <i class="text-lg leading-none {{ tab?.icon }}"></i>
    <span class="hidden lg:flex text-sm"> {{ tab?.title }} </span>
  </div>
</div>
<div class="ml-auto flex items-center gap-1 sm:gap-2 md:gap-4" *ngIf="bar">
  <ng-container *ngTemplateOutlet="bar"></ng-container>
</div>
