<div class="banner">
  <a
    class="banner__logo cursor-pointer"
    href="https://alterhomestays.com/"
    target="_blank"
    *tenancy="'tenant'; on: 'alterhome'"
  >
    <img src="/assets/images/logo-login.png" alt="logo" />
  </a>
</div>
<section class="form-section">
  <form (submit)="login($event)" [formGroup]="form">
    <h2>Kairós</h2>
    <div class="form__control">
      <label for="email" class="text-soot"> Correo: </label>
      <input class="input" formControlName="email" type="email" autofocus />
    </div>
    <div class="form__control">
      <label for="password" class="text-soot"> Contraseña: </label>
      <input class="input" formControlName="password" type="password" />
    </div>
    <div class="form__actions">
      <button [disabled]="form.invalid" class="color-primary">Iniciar Sesión</button>
    </div>
  </form>
</section>
