<ng-container *wait="ticket$; value as ticket">
  <section class="flex flex-row flex-nowrap gap-2 pb-2 border-b border-smoke">
    <span
      class="shadow-sm p-2 text-sm rounded-box after:m-1"
      [ngClass]="{
        'bg-smoke': ticket.status === 'pending',
        'bg-info': ticket.status === 'running',
        'bg-success': ticket.status === 'resolved',
        'bg-error': ticket.status === 'canceled',
        'bg-accent': ticket.status === 'approval',
        notify: ticket?.warning
      }"
    >
      <i class="{{ ticket?.icon }}"></i>
      <b>{{ ticket?.action }}</b>
    </span>
    <span class="spacer"></span>
    <span class="icon-info">
      <i class="ri-group-2-line"></i>
      <b>{{ ticket?.occupants?.adults || 0 }}:{{ ticket?.occupants?.children || 0 }}</b>
    </span>
    <span
      class="icon-info"
      [quickedit]="'time'"
      [quickeditTitle]="'Hora'"
      [quickeditValue]="ticket?.date | date : 'HH:mm'"
      (edited)="editDate($event, ticket)"
    >
      <i class="ri-time-line text-primary"></i>
      <b class="titlebar__hour text-primary"> {{ ticket?.date | date : 'HH:mm' }} </b>
    </span>
  </section>
  <section class="flex flex-col gap-2 px-2 py-0 text-sm">
    <span
      class="pointer-events-none"
      [quickedit]="'select'"
      [quickeditTitle]="'Asignar a'"
      [quickeditValue]="ticket?.assigned_id"
      [quickeditOptions]="users$ | async"
      (edited)="assign($event)"
    >
      <span class="avatar pointer-events-auto">
        <div class="w-6 rounded-full">
          <img
            src="{{ ticket?.assigned_image }}?tenant={{ tenancy.tenant?.id }}"
            onError="this.onerror=null;this.src='/assets/images/default-profile.jpg';"
          />
        </div>
      </span>
      <span class="pointer-events-auto"> {{ ticket?.assigned || 'Sin asignar' }}</span>
    </span>
    <span>
      <i class="ri-book-line text-soot"></i>
      <span> {{ ticket?.title }}</span>
    </span>
    <span>
      <i class="ri-home-3-line text-primary"></i>
      <span class="location__accommodation">{{ ticket?.accommodation }}</span>
    </span>
    <span>
      <i class="ri-map-pin-2-line text-accent"></i>
      <span class="location__address">{{ ticket?.address }}</span>
    </span>
  </section>
  <section class="flex flex-row flex-nowrap gap-2" *ngIf="widgets?.length">
    <!-- TODO: directiva para seleccionar los 'widgets' [CON SU PROPIA CONSULTA (o estado si se implementa)!] -->
  </section>
  <section class="flex flex-row flex-nowrap gap-2 px-2">
    <span class="ml-auto text-sm text-neutral text-opacity-80">
      {{ ticket?.date | date : 'longDate' }}
    </span>
  </section>
  <section
    class="booking flex flex-col flex-nowrap items-start justify-start gap-2 p-2 bg-secondary bg-opacity-10 rounded-box"
    *ngIf="ticket?.booking_id"
  >
    <div class="text-success w-full flex flex-col items-stretch justify-start gap-1">
      <span class="mr-auto">
        <i class="ri-account-circle-line"></i>
        <b>{{ ticket?.client?.replaceAll('"', '')?.split(' ')[0] || '--' }}</b>
      </span>
      <span
        class="icon-info text-success"
        [quickedit]="'time'"
        [quickeditTitle]="'Hora de checkin'"
        [quickeditValue]="ticket?.checkin_schedule"
        (edited)="updateBooking(ticket.booking_id, { checkin_schedule: $event })"
      >
        <i class="ri-flight-land-line"></i>
        <b>{{ ticket?.checkin_schedule }}</b>
      </span>
      <span
        class="icon-info text-success"
        [quickedit]="'time'"
        [quickeditTitle]="'Hora de checkout'"
        [quickeditValue]="ticket?.checkout_schedule"
        (edited)="updateBooking(ticket.booking_id, { checkout_schedule: $event })"
      >
        <i class="ri-flight-takeoff-line"></i>
        <b>{{ ticket?.checkout_schedule }}</b>
      </span>
    </div>
    <span class="text-primary cursor" [copy]="ticket?.localizator">
      <i class="ri-barcode-box-line"></i>
      <b>{{ ticket?.localizator }}</b>
    </span>
  </section>
</ng-container>
