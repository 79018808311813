import { AccommodationMenuComponent } from '@/pms/components/accommodation-menu/accommodation-menu.component';
import { ListComponent } from '@/ux/components/list/list.component';
import { TenantCardComponent } from '@admin/components/tenant-card/tenant-card.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkMenu, CdkMenuItem, CdkMenuTrigger } from '@angular/cdk/menu';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { DialogModule } from '@mukhuve/ngx/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ChecklistCardComponent } from '@operations/components/checklist-card/checklist-card.component';
import { ChecklistActionComponent } from '@operations/components/checklist-action/checklist-action.component';
import { TicketIconComponent } from '@operations/components/ticket-icon/ticket-icon.component';
import { BookingFormComponent } from '@pms/components/booking-form/booking-form.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { NgxColorsModule } from 'ngx-colors';
import { QuillEditorComponent } from 'ngx-quill';
import { SwiperModule } from 'swiper/angular';
import { FormDialogComponent } from '../legacy/components/form-dialog/form-dialog.component';
import { ResourceFormComponent } from '../legacy/components/resource-form/resource-form.component';
import { DropdownComponent } from '../ux/components/dropdown/dropdown.component';
import { MinputComponent } from '../ux/components/minput/minput.component';
import { StepperComponent } from '../ux/components/stepper/stepper.component';
import { FilterPipe } from '../ux/pipes/filter.pipe';
import { OrderPipe } from '../ux/pipes/order.pipe';
import { SanitizePipe } from '../ux/pipes/sanitize.pipe';
import { UXModule } from '../ux/ux.module';
import { FileDropDirective } from './components/attachments/directive/file-drop.directive';
import { ChecklistComponent } from './components/checklist/checklist.component';
import { CommentInputComponent } from './components/comments/comment-input/comment-input.component';
import { CommentMessageComponent } from './components/comments/comment-message/comment-message.component';
import { CommentsComponent } from './components/comments/comments.component';
import { FileInputComponent } from './components/file-input/file-input.component';
import { ListInputComponent } from './components/list-input/list-input.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { NotificationCardComponent } from './components/notification-card/notification-card.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { TagsInputComponent } from './components/tags-input/tags-input.component';
import { TicketBarComponent } from './components/ticket-bar/ticket-bar.component';
import { TicketCardComponent } from './components/ticket-card/ticket-card.component';
import { TicketDetailComponent } from './components/ticket-detail/ticket-detail.component';
import { TicketLiComponent } from './components/ticket-li/ticket-li.component';
import { TicketViewComponent } from './components/ticket-view/ticket-view.component';
import { CanDirective } from './directives/can.directive';
import { CountUpDirective } from './directives/count-up.directive';
import { DraggableDirective } from './directives/draggable.directive';
import { LoadingDirective } from './directives/loading.directive';
import { MagnifierDirective } from './directives/magnifier.directive';
import { PlacesAutocompleteDirective } from './directives/places-autocomplete.directive';
import { PopperDirective } from './directives/popper.directive';
import { TenancyDirective } from './directives/tenancy.directive';
import { getPaginatorIntl } from './lang-paginator-intl';
import { AttachmentsComponent } from './components/attachments/attachments.component';

const STANDALONE: any[] = [
  StepperComponent,
  DropdownComponent,
  FilterPipe,
  SanitizePipe,
  OrderPipe,
  TicketIconComponent,
  MinputComponent,
  ChecklistActionComponent,
  ChecklistCardComponent,
  TenantCardComponent,
  CanDirective,
  NotificationsComponent,
  TagsInputComponent,
  NotificationCardComponent,
  QuillEditorComponent,
  AccommodationMenuComponent,
  CommentMessageComponent,
  CommentInputComponent,
  ListComponent,
  TenancyDirective,
  AttachmentsComponent
];

const SHARED_IMPORTS: any[] = [
  CommonModule,
  FormsModule,
  RouterModule,
  ReactiveFormsModule,
  OverlayModule,
  CdkStepperModule,
  CdkAccordionModule,
  CdkTableModule,
  DragDropModule,
  ClipboardModule,
  SwiperModule,
  NgApexchartsModule,
  NgxColorsModule,
  GoogleMapsModule,
  DialogModule,
  UXModule,
  CdkMenu,
  CdkMenuItem,
  CdkMenuTrigger
];

const DECLARATIONS: any[] = [
  ListInputComponent,
  FileInputComponent,
  CommentsComponent,
  TicketDetailComponent,
  FileDropDirective,
  ChecklistComponent,
  FileDropDirective,
  DraggableDirective,
  TicketViewComponent,
  TicketBarComponent,
  TicketLiComponent,
  MagnifierDirective,
  LoadingDirective,
  MultiSelectComponent,
  PlacesAutocompleteDirective,
  CountUpDirective,
  PopperDirective,
  TicketCardComponent,
  // TODO: Move to Legacy
  ResourceFormComponent,
  FormDialogComponent,
  BookingFormComponent
];
const MAT_IMPORTS: any[] = [
  MatFormFieldModule,
  MatInputModule,
  MatCheckboxModule,
  MatMenuModule,
  MatBadgeModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatNativeDateModule,
  MatDatepickerModule,
  MatSliderModule
];

@NgModule({
  imports: [TranslateModule, ...STANDALONE, ...SHARED_IMPORTS, ...MAT_IMPORTS],
  declarations: DECLARATIONS,
  exports: [...STANDALONE, ...SHARED_IMPORTS, ...MAT_IMPORTS, ...DECLARATIONS, TranslateModule],
  providers: [
    MatDatepickerModule,
    { provide: MAT_DATE_LOCALE, useValue: 'es' },
    { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
  ]
})
export class SharedModule {}
