import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { filter, map, mergeMap, tap } from 'rxjs/operators';
import { UXService } from 'src/app/ux/services/ux.service';
import { Comment } from '../models/comment';
import { Attachment } from '../models/attachment';

@Injectable({ providedIn: 'root' })
export class CommentsService {
  ux = inject(UXService);
  http = inject(HttpClient);

  create(payload: Comment) {
    const body = new FormData();
    let comment = payload.toRequest();
    body.append('content', comment.content);
    body.append('data', comment.data);
    body.append('message', comment.message);
    body.append('extras', comment.extras);
    body.append('ticket_id', comment.ticketId);

    comment.attachments.forEach((attachment: Attachment) => {
      body.append(`files[]`, attachment.file)
    });

    return this.http
      .post('//communications/comments', body)
      .pipe(map((data) => new Comment(data)));;
  }

  update(id: number | string, payload: Comment) {
    const body = new FormData();
    let comment = payload.toRequest();
    body.append('content', comment.content);
    body.append('data', comment.data);
    body.append('message', comment.message);
    body.append('extras', comment.extras);
    body.append('ticket_id', comment.ticketId);
    body.append('_method', 'PUT');

    comment.attachments.forEach((attachment: Attachment) => {
      body.append(`files[]`, attachment.file)
    });

    return this.http
      .post(`//communications/comments/${id}`, body)
      .pipe(map((data) => new Comment(data)));
  }

  delete(id: number | string) {
    return this.ux.confirm(`¿Está seguro de eliminar el comentario?`).pipe(
      filter((result) => !!result),
      mergeMap(() => this.http.delete(`//communications/comments/${id}`)),
      tap({
        next: () => this.ux.notifier.success('¡Comentario eliminado!'),
        error: () => this.ux.notifier.error('¡Error al eliminar el comentario!')
      })
    );
  }

  list(search = '', ticketId?: string | number) {
    const params = { search, ticketId };

    const filter = (comment: Comment) => {
      const { ticketId: oticket } = comment;
      let success = true;
      if (ticketId) success &&= ticketId == oticket;
      if (search) {
        const sanitized = search.replace(/[#-.]|[[-^]|[?|{}]/g, '\\$&');
        const regexp = RegExp(`.*${sanitized}.*`, 'igm');
        success &&= !!JSON.stringify(comment.message).match(regexp);
      }

      return success;
    };

    return this.http
      .get<Comment[]>('//communications/comments', { params })
      .pipe(map((data) => data?.map((c) => new Comment(c))?.filter(filter)));
  }

  propagate(id: number | string) {
    return this.http.post(`//communications/comments/${id}/propagate`, {});
  }
}
