<span
  class="ticket-priority"
  [ngClass]="{
    'bg-error': ticket?.priority === 1,
    'bg-warning': ticket?.priority === 2,
    'bg-info': ticket?.priority === 3,
    'bg-soot': ticket?.priority === 4
  }"
></span>
<i
  class="{{ ticket?.icon || 'ri-coupon-3-line' }} cursor-pointer"
  [class.notify]="ticket?.warning"
></i>
