import { Component, HostBinding, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { DialogService } from '@mukhuve/ngx/dialog';
import { TranslateService } from '@ngx-translate/core';
import { filter } from 'rxjs';
import { TenancyService } from 'src/app/admin/services/tenancy.service';
import { AppService } from 'src/app/core/services/app.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { NotificationsComponent } from 'src/app/shared/components/notifications/notifications.component';
import { SharedModule } from '../../shared.module';
import { NotificationsService } from '@/shared/services/notifications.service';

@Component({
  standalone: true,
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  imports: [SharedModule]
})
export class NavComponent {
  app = inject(AppService);
  auth = inject(AuthService);
  tenancy = inject(TenancyService);
  translate = inject(TranslateService);
  dialog = inject(DialogService);
  router = inject(Router);
  notifications = inject(NotificationsService);

  @HostBinding('class.more')
  more: boolean = false;

  @HostBinding()
  hidden: boolean = false;

  dev = !environment.production;
  language = 'es';
  actives: Record<string, boolean> = {};

  get tenant() {
    const tenant = this.tenancy.tenant;
    return tenant?.name || tenant?.id;
  }

  constructor() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntilDestroyed()
      )
      .subscribe(() => (this.more = false));
  }

  toggleLanguage() {
    this.language = this.language === 'en' ? 'es' : 'en';
    this.translate.use(this.language);
  }

  notification() {
    this.dialog.open(NotificationsComponent, {
      classes: 'without-min',
      type: 'shy'
    });
  }
}
