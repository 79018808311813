<quill-editor
  class="comment-input__editor notranslate"
  [modules]="modules"
  [placeholder]="readonly ? '' : placeholder || 'Comenta aqui...'"
  [readOnly]="readonly"
  [(ngModel)]="value"
  (onFocus)="extended = true"
  (onBlur)="onBlur($event)"
  (onEditorCreated)="created()"
  (keyup)="keyup($event)"
>
</quill-editor>

@if (attachments?.length > 0 && !disableAttachments) {
  <div (click)="extended = true">
    <app-attachments
      [readonly]="readonly"
      [attachments]="attachments"
      (remove)="removeAttachment($event)"
      size="sm"
    >
    </app-attachments>
  </div>
}
@if (!readonly) {
  <ng-container>
    <i class="ri-chat-1-line comment-input__suffix"></i>
    <div class="divider m-0 p-0"></div>
    <div class="comment-input__bar">
      <div class="flex items-center">
        <small> Puedes usar '&#64;' mencionar usuarios o '#' para etiquetas</small>
        <div class="flex">
          @if (!disableAttachments) {
            <input
              #fileInput
              hidden
              multiple
              id="file-input"
              type="file"
              (change)="filesChange($event)"
            />
            <button class="btn color-success text-white font-light" (click)="fileInput.click()">
              <i class="ri-file-add-line"></i>
            </button>
          }
          <button class="btn color-success text-white font-light" (click)="submit()">
            <i class="ri-send-plane-fill"></i>
          </button>
        </div>
      </div>
    </div>
  </ng-container>
}
