import { AppControl } from '@/ux/models/control';
import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
  ViewChild,
  forwardRef
} from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule
} from '@angular/forms';
import { QuillEditorComponent, QuillModules } from 'ngx-quill';
import { Comment } from 'src/app/core/models/comment';
import { TagsInputComponent } from '../../tags-input/tags-input.component';
import { buildModules, getMentions } from '../comments.constants';
import { CommentTrigger } from '../comments.types';
import { isEmpty } from '@/core/helpers/object-helpers';
import { AttachmentsComponent } from "../../attachments/attachments.component";
import { Attachment } from '@/core/models/attachment';

@Component({
  standalone: true,
  imports: [
    TagsInputComponent,
    QuillEditorComponent,
    FormsModule,
    ReactiveFormsModule,
    AttachmentsComponent,
  ],
  selector: 'app-comment-input',
  templateUrl: './comment-input.component.html',
  styleUrls: ['./comment-input.component.scss'],
  host: { class: 'comment-input' },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CommentInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => CommentInputComponent),
      multi: true
    }
  ]
})
export class CommentInputComponent extends AppControl {
  @ViewChild(QuillEditorComponent)
  editor?: QuillEditorComponent;

  @Input()
  triggers: CommentTrigger[] = [];

  @HostBinding('class.readonly')
  @Input()
  readonly = false;

  @HostBinding('class.extended')
  @Input()
  extended: boolean = false;

  @Input()
  placeholder: string = '';

  @Input()
  clean = true;

  @Input()
  delta: any;

  @Input()
  attachments?: Attachment[] = [];

  @Output()
  send: EventEmitter<any> = new EventEmitter();

  modules: QuillModules;

  disableAttachments = true;

  ngOnInit() {
    this.modules = buildModules(this.triggers);
  }

  created() {
    if (!isEmpty(this.delta)) {
      this.editor.quillEditor.setContents(this.delta);
    }
  }

  keyup({ code, ctrlKey }: KeyboardEvent) {
    if (code === 'Enter' && ctrlKey) {
      this.submit();
    }
  }

  submit() {
    const quill = this.editor.quillEditor;
    const comment = new Comment({
      content: this.value,
      data: quill.getContents(),
      message: quill.getText(),
      extras: getMentions(this.editor),
      attachments: this.attachments
    });

    this.send.emit(comment);

    if (this.clean) {
      this.delta = null;
      this.value = '';
      this.attachments = [];
    }
  }

  onBlur(event: any) {
    const quill = this.editor.quillEditor;
    const text = quill.getText()?.trim();
    this.extended = !!text;
    this.onTouched(event);
  }

  filesChange($event: any) {
    const files = $event.target.files;
    let newFiles: any[] = Array.from(files).map((file: File) => Attachment.fromFile(file));
    this.attachments.push(...newFiles);
    $event.target.value = '';
  }

  removeAttachment(attachment: Attachment) {
    const id = attachment.id;
    const index = this.attachments.findIndex(
      (attachment) => attachment.id === id
    );
    if (index !== -1) this.attachments.splice(index, 1);
    const hasText = this.editor?.quillEditor.getText()?.trim().length > 0;
    this.extended = hasText || this.attachments.length > 0;
  }

}
