import { UsersService } from '@admin/services/users.service';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
  signal
} from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { delay, map, tap } from 'rxjs/operators';
import { Comment } from 'src/app/core/models/comment';
import { CommentsService } from 'src/app/core/services/comments.service';
import { ResourcesService } from 'src/app/legacy/services/resources.service';
import { CommentTrigger } from './comments.types';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
  @ViewChild('box', { static: false })
  box!: ElementRef<HTMLDivElement>;

  @Input()
  ticketId: any;

  @Input()
  readonly: boolean = false;

  @Output()
  modified: EventEmitter<void> = new EventEmitter();

  search: string = '';
  loading = signal(false);
  triggers$: Observable<CommentTrigger[]>;
  comments = new BehaviorSubject<Comment[]>([]);
  comments$ = this.comments.asObservable();

  constructor(
    public users: UsersService,
    public service: CommentsService,
    public resources: ResourcesService
  ) {}

  ngOnInit() {
    const requests = [this.users.list(), this.resources.lite('/tags')];
    this.triggers$ = combineLatest(requests).pipe(
      map(([users, tags]) => {
        const tagsItems = tags.map(({ id, name }) => ({ id, value: name }));
        const usersItems = users.map((user) => ({
          id: user.id,
          value: `${user.name} ${user.surname ?? ''}`.trim()
        }));
        return [
          { key: 'users', char: '@', items: usersItems },
          { key: 'tags', char: '#', items: tagsItems }
        ];
      })
    );

    this.load();
  }

  add(comment: Comment) {
    if (!this.ticketId) return;
    comment.ticketId = this.ticketId;
    this.service.create(comment).subscribe(() => this.load());
  }

  edit(comment: Comment) {
    if (!this.ticketId) return;
    comment.ticketId = this.ticketId;
    this.service.update(comment.id, comment).subscribe(() => this.load());
  }

  remove(comment: Comment) {
    this.service.delete(comment.id).subscribe(() => this.load());
  }

  load() {
    this.loading.set(true);
    this.service
      .list(this.search, this.ticketId)
      .pipe(
        tap(() => {
          this.loading.set(false);
          setTimeout(() => {
            const objDiv = this.box?.nativeElement;
            objDiv.scrollTop = objDiv.scrollHeight;
          }, 1000);
        })
      )
      .subscribe((d) => this.comments.next(d));
  }
}
