import { NgFor, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import { FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DropdownComponent } from '../../components/dropdown/dropdown.component';
import { Option } from '../../directives/option.directive';
import { AppOptionControl } from '../../models/option-control';
import { SanitizePipe } from '../../pipes/sanitize.pipe';
import { FilterPipe } from '../../pipes/filter.pipe';

@Component({
  standalone: true,
  selector: 'app-select',
  imports: [
    DropdownComponent,
    Option,
    SanitizePipe,
    FormsModule,
    NgIf,
    NgFor,
    FilterPipe
  ],
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectComponent),
      multi: true
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { class: 'control bg-smoke', tabindex: '0' }
})
export class SelectComponent extends AppOptionControl<any> {
  @ContentChildren(Option)
  override options: QueryList<Option> = new QueryList<Option>();

  @ViewChild(DropdownComponent)
  dropdown!: DropdownComponent;

  @Input()
  placeholder: string;

  @Input()
  filter: boolean = true;

  @Input()
  arrow: boolean = true;

  search: string;

  get label() {
    return this.current?.label ?? this.placeholder;
  }

  constructor(
    public el: ElementRef,
    public cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef
  ) {
    super();
  }

  @HostListener('click')
  open() {
    this.dropdown?.open({
      container: this.viewContainerRef,
      connectedTo: this.el
    });
  }

  close() {
    this.dropdown?.close();
  }

  override refresh() {
    super.refresh();
    this.cdr.detectChanges();
  }
}
