import { Colors } from 'src/app/core/helpers/colors';
import { parse } from 'date-fns';

export class AccommodationEvent {
  id?: string | number;
  start: Date;
  end?: Date;
  title: string;
  color: string;
  status: string;
  type: string;
  style?: {};

  get key() {
    return `${this.type}:${this.status}`;
  }

  constructor(data?: Partial<AccommodationEvent>) {
    Object.assign(this, data);
    if (!this.color) this.color = Colors.soot;
    if (!this.style)
      this.style = {
        'background-color': this.color,
        color: Colors.ground
      };
  }

  dayEdge(date: Date) {
    if (
      date.getTime() < this.start.getTime() ||
      date.getTime() > this.end.getTime()
    )
      return 'none';

    return date.getTime() === this.start.getTime()
      ? 'start'
      : date.getTime() === this.end.getTime()
      ? 'end'
      : 'internal';
  }

  static fromResponse(data: any) {
    const key = `${data.type}:${data.status}`;
    const colorMap = {
      'regular:unpaid': '#f9cb9c',
      'regular:paid': '#ff94cd',
      'regular:confirmed': Colors.success,
      'regular:canceled': Colors.error,
      'lock:owner': Colors.info,
      'lock:unavailable': '#ee7c24',
      'lock:canceled': Colors.error
    };

    return new AccommodationEvent({
      ...data,
      end: parse(data.end, 'yyyy-MM-dd', new Date()),
      start: parse(data.start, 'yyyy-MM-dd', new Date()),
      color: colorMap[key] || '#bab8b8'
    });
  }
}
