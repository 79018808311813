import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeEs from '@angular/common/locales/es';
import { ApplicationConfig, ErrorHandler, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter, withComponentInputBinding } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { ConfigService } from '@core/services/config.service';
import { ErrorHandlerService } from '@core/services/error-handler.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { provideQuillConfig } from 'ngx-quill';
import MagicUrl from 'quill-magic-url';
import Mention from 'quill-mention';
import { TenancyService } from './admin/services/tenancy.service';
import { provideInitializer } from './app.init';
import { routes } from './app.routes';
import { AuthService } from './core/services/auth.service';
import { EventsService } from './core/services/events.service';
import { SharedModule } from './shared/shared.module';
import { provideToastr } from 'ngx-toastr';

registerLocaleData(localeEs, 'es');

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    provideToastr({
      positionClass: 'toast-top-right',
      timeOut: 10000,
      progressBar: true,
      tapToDismiss: true
    }),
    provideQuillConfig({
      modules: {
        syntax: true,
        toolbar: []
      },
      customModules: [
        { implementation: MagicUrl, path: 'modules/magicUrl' },
        { implementation: Mention, path: 'modules/mention' }
      ]
    }),
    provideInitializer([TenancyService, AuthService, ConfigService, EventsService]),
    importProvidersFrom(SharedModule),
    importProvidersFrom(CommonModule),
    importProvidersFrom(HttpClientModule),
    importProvidersFrom(CoreModule),
    importProvidersFrom(
      TranslateModule.forRoot({
        useDefaultLang: true,
        loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) =>
            new TranslateHttpLoader(http, './assets/i18n/', '.json'),
          deps: [HttpClient]
        }
      })
    ),
    { provide: ErrorHandler, useClass: ErrorHandlerService },
    { provide: LOCALE_ID, useValue: 'es' }
  ]
};
