import { Injectable, inject } from '@angular/core';
import { DialogService } from '@mukhuve/ngx/dialog';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MessageComponent } from '../components/message/message.component';
import { QuickeditComponent } from '../dialogs/quickedit/quickedit.component';
import { MessageConfig } from '../types/message.types';
import { NotifierService } from './notifier.service';

@Injectable({ providedIn: 'root' })
export class UXService {
  dialog = inject(DialogService);
  notifier = inject(NotifierService);

  confirmUpdateOrDelete(msg?: string) {
    msg ||= '¿Está seguro de realizar esta operación?';

    return this.confirm(msg);
  }

  quickedit(config: any) {
    const { type, title, value, placeholder, options } = config;

    const ref = this.dialog.open(QuickeditComponent, {
      classes: 'p-4 bg-white rounded-md shadow-md',
      inputs: { type, title, value, placeholder, options }
    });

    return ref.events.pipe(
      filter((e) => e.type === 'beforeclose'),
      map(({ data }) => data)
    );
  }

  message(title: string, message: string, config: any = {}) {
    const actions = config?.actions ?? [{ title: 'Ok', key: 'ok', color: 'primary', close: true }];
    const ref = this.dialog.open(MessageComponent, {
      classes: 'without-min',
      inputs: { title, message, actions }
    });

    return ref.events.pipe(
      filter((e) => e.type === 'custom'),
      map(({ data }) => data?.type)
    );
  }

  confirm(
    message = '¿Está seguro de realizar esta operación?',
    config: MessageConfig = {}
  ): Observable<any> {
    const { title, actions }: MessageConfig = {
      title: 'Confirmación',
      actions: [
        { label: 'No', value: false, color: 'smoke', close: true },
        { label: 'Si', value: true, color: 'primary', close: true }
      ],
      ...config
    };
    const inputs = { title, message, actions };

    return this.dialog.open(MessageComponent, { inputs }).events.pipe(
      filter((e) => e.type === 'custom'),
      map(({ data }) => data)
    );
  }
}
