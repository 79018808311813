<div class="comment-content">
  <div class="comment-content__titlebar">
    <strong class="mat-text-primary flex items-center gap-1">
      @if (comment.isAutomatic) {
        <i class="ri-robot-2-line"></i>
        Sistema
      } @else {
        {{ comment.user }}
      }
    </strong>
    <span [ngClass]="!readonly ? 'titlebar-date' : 'titlebar-date-editable'" [hidden]="editable">
      {{ comment?.createdAt | date: 'medium' }}
    </span>
    @if (!readonly) {
      <div class="titlebar-actions">
        <a
          *ngIf="comment.data && itsme"
          (click)="editable ? input.submit() : (editable = true)"
          class="cursor-pointer"
          [ngClass]="{ 'text-info': !editable, 'text-success': editable }"
        >
          <i class="ri-{{ editable ? 'check' : 'pencil' }}-line"></i>
        </a>
        <a *ngIf="isEditable" (click)="restore()" class="cursor-pointer text-soot">
          <i class="ri-close-line"></i>
        </a>
        <ng-container *ngIf="!isEditable">
          <a *can="'!host'" class="cursor-pointer text-neutral" [cdkMenuTriggerFor]="menu">
            <i class="ri-more-2-line"></i>
          </a>
          <ng-template #menu>
            <div class="dropdown-content" cdkMenu>
              <li *ngIf="itsme && !isEditable" (click)="remove()">
                <a class="cursor-pointer text-error">
                  <i class="ri-delete-bin-line"></i>
                  <span>Eliminar</span>
                </a>
              </li>
              <li *can="'control+3,operation+3'">
                <a class="cursor-pointer text-primary" (click)="propagate()">
                  <i class="ri-chat-forward-line"></i>
                  <span>Propagar</span>
                </a>
              </li>
            </div>
          </ng-template>
        </ng-container>
      </div>
    }
  </div>
  <app-comment-input
    #input
    class="comment-content__message"
    [triggers]="triggers"
    [readonly]="!isEditable"
    [extended]="true"
    [clean]="false"
    [delta]="data"
    [attachments]="comment.attachments"
    [(ngModel)]="content"
    (send)="edit($event)"
    (blur)="restore()"
  ></app-comment-input>
</div>
<div class="comment-aside">
  <div class="comment-aside__image">
    <img
      class="w-full h-full object-cover"
      [src]="comment.picture"
      onError="this.onerror=null;this.src='/assets/images/default-profile.jpg';"
    />
  </div>
</div>
