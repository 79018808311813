<form [formGroup]="form">
  <app-stepper #stepper>
    <cdk-step>
      <div class="form">
        <div class="form__control">
          <label for="title"> Título </label>
          <input class="input" formControlName="title" type="text" placeholder="Título" />
        </div>
        <div>
          <div class="form__control" *ngIf="!hidden?.action_id">
            <label for="actions"> Acción </label>
            <app-select formControlName="action_id">
              <app-option *ngFor="let action of auxs.actions || []" [value]="action.id">
                {{ action?.name }}
              </app-option>
            </app-select>
          </div>
          <div class="form__control" *ngIf="!hidden?.accommodation_id">
            <label for="accommodations"> Alojamiento </label>
            <app-select formControlName="accommodation_id">
              <app-option
                *ngFor="let accommodation of auxs.accommodations || []"
                [value]="accommodation.id"
              >
                {{ accommodation?.name }}
              </app-option>
            </app-select>
          </div>
        </div>
        <div>
          <div class="form__control" *ngIf="!hidden?.date">
            <label for="date"> Fecha </label>
            <input class="input" type="datetime-local" formControlName="date" />
          </div>
          <div class="form__control" *ngIf="!hidden?.assigned_id">
            <label for="assignments"> Asignar </label>
            <app-select formControlName="assigned_id">
              <app-option [value]="null">
                <span> Sin asignar </span>
              </app-option>
              <app-option *ngFor="let assignment of auxs.assignments || []" [value]="assignment.id">
                {{ assignment?.name }}
              </app-option>
            </app-select>
          </div>
        </div>
      </div>
    </cdk-step>
    <cdk-step>
      <div class="form">
        <div class="form__control">
          <label for="priority"> Prioridad</label>
          <app-select formControlName="priority">
            <app-option
              *ngFor="let priority of ['Muy Alta', 'Alta', 'Media', 'Baja']; let i = index"
              [value]="i"
            >
              {{ priority }}
            </app-option>
          </app-select>
        </div>
        <div class="form__control">
          <label for="description"> Descripción</label>
          <textarea
            class="textarea"
            formControlName="description"
            placeholder="Descripcion del ticket..."
          ></textarea>
        </div>
      </div>
    </cdk-step>
  </app-stepper>
  <div class="form__actions">
    <button *ngIf="stepper.selectedIndex > 0" class="btn btn-smoke" (click)="stepper.previous()">
      <i class="ri-arrow-left-line"></i>
    </button>
    <button
      *ngIf="stepper.selectedIndex < stepper.steps?.length - 1"
      class="btn btn-info text-base-100"
      matTooltip="Siguiente"
      [disabled]="form.invalid"
      (click)="stepper.next()"
    >
      <i class="ri-arrow-right-line"></i>
    </button>
    <app-button
      class="btn-primary text-base-100"
      matTooltip="Guardar"
      [block]="true"
      [disabled]="form.invalid"
      (click)="submit($event)"
    >
      <i class="ri-save-3-line"></i>
    </app-button>
    <app-button
      class="btn-success text-base-100"
      matTooltip="Guardar y abrir"
      [block]="true"
      [disabled]="form.invalid"
      (click)="submit($event, true)"
    >
      <i class="ri-save-3-line"> </i>
      <i class="ri-external-link-line"></i>
    </app-button>
  </div>
</form>
