@if (!loading()) {
  @if (ticket$ | async; as ticket) {
    <div class="ticket-header">
      <app-ticket-bar [ticket]="ticket" [around]="around" [isLoading]="loading()" (updated)="load(true, true)"
        [readonly]="readonly">
        <button [disabled]="readonly" tabindex="-1" class="btn btn-ghost lg:hidden"
          (click)="see = see == 'comments' ? 'detail' : 'comments'">
          <i class="ri-{{ see === 'comments' ? 'file-info-line' : 'chat-1-line' }}"></i>
        </button>
      </app-ticket-bar>
    </div>
    <div class="ticket-content">
      @if (ticket) {
      <app-ticket-detail #detail [class.no-see]="see !== 'detail'" [ticket]="ticket" [readonly]="readonly" fileDrop
        (modified)="load(true)" (fileDropped)="detail.addAttachment($event)">
      </app-ticket-detail>
      }
      <app-comments #comments [ticketId]="id" [class.hidden]="!ticket" [readonly]="readonly"
        [class.no-see]="see !== 'comments'" (modified)="load(true, true)"></app-comments>
    </div>
  }
}@else {
  <div class="flex justify-center items-center flex-1">
    <i class="ri-loader-2-line spin text-2xl text-primary"></i>
  </div>
}